var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-md-row flex-column",
                    staticStyle: { gap: "0.5rem" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "CMultiSelect",
                          {
                            attrs: {
                              selectionType: "tags",
                              multiple: false,
                              selection: true,
                              selected: _vm.selectedInvoiceFilter,
                            },
                            on: {
                              "update:selected": function ($event) {
                                _vm.selectedInvoiceFilter = $event
                              },
                              update: _vm.invoiceFilter,
                            },
                          },
                          [
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v("All"),
                            ]),
                            _c("option", { attrs: { value: "paid" } }, [
                              _vm._v("Paid"),
                            ]),
                            _c("option", { attrs: { value: "unpaid" } }, [
                              _vm._v("Unpaid"),
                            ]),
                            _c("option", { attrs: { value: "mailed" } }, [
                              _vm._v("Mailed"),
                            ]),
                            _c("option", { attrs: { value: "not-mailed" } }, [
                              _vm._v("Not Mailed"),
                            ]),
                            _c("option", { attrs: { value: "transfered" } }, [
                              _vm._v("Transfered"),
                            ]),
                            _c(
                              "option",
                              { attrs: { value: "not-transfered" } },
                              [_vm._v("Not Transfered")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select company..",
                            options: _vm.allCompanies,
                          },
                          on: { input: _vm.companyFilter },
                          model: {
                            value: _vm.selectedCompany,
                            callback: function ($$v) {
                              _vm.selectedCompany = $$v
                            },
                            expression: "selectedCompany",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "end",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": { external: true, lazy: true, placeholder: "..." },
          tableFilterValue: _vm.search,
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No MenutePay invoices found!",
          },
        },
        on: {
          "update:tableFilterValue": function ($event) {
            _vm.search = $event
          },
          "update:table-filter-value": [
            function ($event) {
              _vm.search = $event
            },
            _vm.tableSearch,
          ],
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" #" + _vm._s(item.id) + " ")])]
            },
          },
          {
            key: "ivMailed",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c("CIcon", {
                      class: `text-${item.ivMailed ? "success" : "danger"}`,
                      attrs: {
                        height: 32,
                        name: `cil-${item.ivMailed ? "check-alt" : "x"}`,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "ivTotIncl",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("toCurrency")(
                          item.ivTotIncl,
                          "remove_thousand_separator"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "balance",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "strong",
                    {
                      class: `text-${
                        item.total_payment - item.ivTotIncl >= 0
                          ? "success"
                          : "danger"
                      }`,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toCurrency")(
                              item.total_payment - item.ivTotIncl,
                              "signDisplay"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]
            },
          },
          {
            key: "ivNr",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.viewInvoice(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.ivNr) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "ivDate-filter",
            fn: function () {
              return [
                _c("div", { staticClass: "input-group mb-1" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("From"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.invoices.startDate,
                        expression: "filters.invoices.startDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "date" },
                    domProps: { value: _vm.filters.invoices.startDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.invoices,
                          "startDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("To"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.invoices.endDate,
                        expression: "filters.invoices.endDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "date" },
                    domProps: { value: _vm.filters.invoices.endDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.invoices,
                          "endDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "ivDate",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  {
                    class: _vm.isToday(item.ivDate)
                      ? "text-danger font-weight-bold"
                      : "",
                  },
                  [_vm._v(" " + _vm._s(item.ivDate) + " ")]
                ),
              ]
            },
          },
          {
            key: "company",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _vm._v(
                      " #" + _vm._s(item.company ? item.company.id : null) + " "
                    ),
                    _c(
                      "CLink",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { target: "_blank" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.company ? item.company.name : null) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: "Download",
                            },
                            expression:
                              "{\n            html: true,\n            content: 'Download'\n          }",
                          },
                        ],
                        attrs: { size: "sm", color: "primary", shape: "pill" },
                        on: {
                          click: function ($event) {
                            return _vm.viewInvoice(item)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-cloud-download" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: "Send E-Mail",
                            },
                            expression:
                              "{\n            html: true,\n            content: 'Send E-Mail'\n          }",
                          },
                        ],
                        staticClass: "ml-1",
                        attrs: { size: "sm", color: "info", shape: "pill" },
                        on: {
                          click: function ($event) {
                            return _vm.sendInvoice(item)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-send" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: "Payments",
                            },
                            expression:
                              "{\n            html: true,\n            content: 'Payments'\n          }",
                          },
                        ],
                        staticClass: "ml-1",
                        attrs: {
                          size: "sm",
                          color: item.is_paid ? "success" : "warning",
                          shape: "pill",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showPayments(item)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-euro" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: { size: "sm", color: "success" },
                        on: {
                          click: function ($event) {
                            _vm.makeInvoicesModal = true
                          },
                        },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-send" } }),
                        _vm._v(" Create Invoices "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: `Download All Filtered Invoices`,
                            },
                            expression:
                              "{\n              html: true,\n              content: `Download All Filtered Invoices`\n            }",
                          },
                        ],
                        attrs: {
                          size: "sm",
                          color: "primary",
                          disabled: _vm.total == 0,
                        },
                        on: { click: _vm.downloadZip },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-cloud-download" } }),
                        _vm._v(" Download All "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          size: "sm",
                          color: "info",
                          shape: "pill",
                          variant: "ghost",
                        },
                        on: { click: _vm.transferInvoice },
                      },
                      [
                        _c("CIcon", {
                          attrs: { name: "cil-data-transfer-up" },
                        }),
                        _vm._v(" Transfer All Untransferred Invoices "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectedInvoiceFilter == "not-mailed"
                  ? _c(
                      "div",
                      [
                        _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "info",
                              shape: "pill",
                              variant: "ghost",
                            },
                            on: { click: _vm.sendAllUnmailedInvoices },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-send" } }),
                            _vm._v(" Send All Unmailed Invoices by Email "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "make-invoices",
          attrs: {
            title: "Create Invoices",
            size: "sm",
            color: "success",
            centered: "",
            show: _vm.makeInvoicesModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.makeInvoicesModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.makeInvoicesModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: !_vm.selectedMonth,
                        size: "sm",
                        color: "success",
                      },
                      on: { click: _vm.makeInvoices },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-send" } }),
                      _vm._v(" Create "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("CInput", {
            attrs: { type: "month", max: _vm.prevMonth },
            model: {
              value: _vm.selectedMonth,
              callback: function ($$v) {
                _vm.selectedMonth = $$v
              },
              expression: "selectedMonth",
            },
          }),
        ],
        1
      ),
      _c("payments-aside", {
        attrs: { invoice: _vm.selectedInvoice },
        on: {
          "update:invoice": function ($event) {
            _vm.selectedInvoice = $event
          },
        },
      }),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }